<template>
  <div>
    <h6 class="mb-2">Mobile OS</h6>
    <v-combobox
      v-model="selected_mobileOS"
      :items="mobile_os"
      label="Choose Mobile Operating Systems"
      outlined
      dense
      item-text="platform_name"
      item-value="version_id"
      multiple
    ></v-combobox>
  </div>
</template>
<script>
export default {
  data: () => ({
    selected_mobileOS: null,
    mobile_os_array: [],
  }),
  props: {
    single_pretarget: {
      type: Object,
    },
    mobile_os: {
      type: Array,
    },
  },
  watch: {
    selected_mobileOS() {
      let mobileOS = this.selected_mobileOS;
      let ids = [];
      if (mobileOS.length) {
        for (let i = 0, len = mobileOS.length; i < len; i++) {
          ids.push(mobileOS[i].version_id);
        }
      }
      this.$emit("setIncludedMobileOperatingSystemIds", ids);
    },
    single_pretarget() {
      if (this.single_pretarget) {
        this.selected_mobileOS = this.single_pretarget
          .includedMobileOperatingSystemIds
          ? this.single_pretarget.includedMobileOperatingSystemIds
          : null;
      }
    },
    mobile_os() {
      if (
        this.mobile_os.length &&
        this.selected_mobileOS &&
        this.selected_mobileOS.length
      ) {
        this.selected_mobileOS.forEach((element) => {
          let allOS = this.mobile_os.find((os) => os.version_id === element);
          this.mobile_os_array.push(allOS);
        });
        this.selected_mobileOS = this.mobile_os_array;
      }
    },
  },
};
</script>
