<template>
  <div>
    <h6 class="mb-2">Creative Dimensions</h6>
    <v-combobox
      v-model="dimension"
      :items="available_dimensions"
      label="Dimensions"
      outlined
      dense
      multiple
      chips
      small-chips
      deletable-chips
    >
    </v-combobox>
  </div>
</template>

<script>
export default {
  data: () => ({
    width:null,
    available_dimensions: [
      "468x60",
      "728x90",
      "250x250",
      "200x200",
      "336x280",
      "300x250",
      "120x600",
      "160x600",
      "320x50",
      "300x50",
      "425x600",
      "300x600",
      "970x90",
      "240x400",
      "980x120",
      "930x180",
      "250x360",
      "580x400",
      "300x1050",
      "480x320",
      "320x480",
      "768x1024",
      "1024x768",
      "480x32",
      "1024x90",
      "970x250",
      "300x100",
      "750x300",
      "750x200",
      "750x100",
      "950x90",
      "88x31",
      "220x90",
      "300x31",
      "320x100",
      "980x90",
      "240x133",
      "200x446",
      "292x30",
      "960x90",
      "970x66",
      "300x57",
      "120x60",
      "375x50",
      "414x736",
      "736x414",
      "320x400",
      "600x314",
    ],
    height: null,
    dimensions: [],
    dimension: [],
  }),
  props: {
    single_pretarget: {
      type: Object,
    },
  },
  watch: {
    dimension(){
      this.dimension.forEach((dimension)=>{
        let all = dimension.split("x");
        this.width=all[0];
        this.height=all[1];
      })
      this.dimensions.push({ height: this.height, width: this.width });
      if (this.dimensions) {
        (this.height = null), (this.width = null);
      }
      this.$emit("setCreativeDimensions", this.dimensions);
    },
    single_pretarget() {
      if (this.single_pretarget.includedCreativeDimensions) {
        for (
          let i = 0;
          i < this.single_pretarget.includedCreativeDimensions.length;
          i++
        ) {
          this.width = this.single_pretarget.includedCreativeDimensions[i].width
            ? this.single_pretarget.includedCreativeDimensions[i].width
            : null;
          this.height = this.single_pretarget.includedCreativeDimensions[i]
            .height
            ? this.single_pretarget.includedCreativeDimensions[i].height
            : null;
        }
      }
    },
  },
};
</script>
