<template>
  <div>
    <h6 class="mb-2">Languages</h6>
    <v-combobox
      v-model="selected_languages"
      :items="languages"
      label="Choose Languages"
      outlined
      dense
      item-text="language_name"
      item-value="language_name"
      multiple
    ></v-combobox>
  </div>
</template>
<script>
export default {
  data: () => ({
    selected_languages: null,
    languages_array: [],
  }),
  props: {
    single_pretarget: {
      type: Object,
    },
    languages: {
      type: Array,
    },
  },
  watch: {
    selected_languages() {
      let languages = this.selected_languages;
      let alllangids = [];
      if (languages.length) {
        for (let i = 0, len = languages.length; i < len; i++) {
          alllangids.push(languages[i].language_code);
        }
      }
      this.$emit("setIncludedLanguages", alllangids);
    },

    single_pretarget() {
      if (this.single_pretarget) {
        this.selected_languages = this.single_pretarget.includedLanguages
          ? this.single_pretarget.includedLanguages
          : null;
      }
    },

    languages() {
      if (
        this.languages &&
        this.selected_languages &&
        this.selected_languages.length
      ) {
        this.selected_languages.forEach((element) => {
          let alllanguages = this.languages.find(
            (language) => language.language_code === element
          );
          this.languages_array.push(alllanguages);
        });
        this.selected_languages = this.languages_array;
      }
    },
  },
};
</script>
