<template>
  <div>
    <h5 class="mb-2">Verticals</h5>
    <v-combobox
      v-model="included_vertical_ids"
      :items="vertical_targeting"
      label="Choose Included Market Specifics"
      outlined
      dense
      item-text="long_name"
      item-value="code"
      multiple
      class="mb-4"
    ></v-combobox>
    <v-combobox
      v-model="excluded_vertical_ids"
      :items="vertical_targeting"
      label="Choose Excluded Market Specifics"
      outlined
      dense
      item-text="long_name"
      item-value="code"
      multiple
    ></v-combobox>
  </div>
</template>

<script>
export default {
  data: () => ({
    included_vertical_ids: null,
    excluded_vertical_ids: null,
    included: [],
    excluded: [],
    prefill_included: [],
    prefill_excluded: [],
  }),
  props: {
    single_pretarget: {
      type: Object,
    },
    vertical_targeting: {
      type: Array,
    },
  },
  watch: {
    included_vertical_ids() {
      let verticals = this.included_vertical_ids;
      if (verticals.length) {
        for (let i = 0, len = verticals.length; i < len; i++) {
          if (verticals[i].children_codes) {
            let children = verticals[i].children_codes
              .replace("{", " ")
              .replace("}", " ");
            let array_children = Object.values(children.split(","));
            let arrayOfNumbers = array_children.map(Number);
            arrayOfNumbers.push(verticals[i].code);
            this.included = arrayOfNumbers;
          }
        }
      }
      this.$emit("setIncludedVerticals", this.included);
    },
    excluded_vertical_ids() {
      let verticals = this.excluded_vertical_ids;
      if (verticals.length) {
        for (let i = 0, len = verticals.length; i < len; i++) {
          if (verticals[i].children_codes) {
            let children = verticals[i].children_codes
              .replace("{", " ")
              .replace("}", " ");
            let array_children = Object.values(children.split(","));
            let arrayOfNumbers = array_children.map(Number);
            arrayOfNumbers.push(verticals[i].code);
            this.excluded = arrayOfNumbers;
          }
        }
      }
      this.$emit("setExcludedVerticals", this.excluded);
    },
    single_pretarget() {
      if (
        this.single_pretarget.verticalTargeting &&
        this.single_pretarget.verticalTargeting.includedIds &&
        this.single_pretarget.verticalTargeting.excludedIds
      ) {
        this.included_vertical_ids = this.single_pretarget.verticalTargeting.includedIds.slice(
          -1
        )
          ? this.single_pretarget.verticalTargeting.includedIds.slice(-1)
          : null;
        this.excluded_vertical_ids = this.single_pretarget.verticalTargeting.excludedIds.slice(
          -1
        )
          ? this.single_pretarget.verticalTargeting.excludedIds.slice(-1)
          : null;
      }
    },
    vertical_targeting() {
      if (this.vertical_targeting) {
        if (this.included_vertical_ids) {
          this.included_vertical_ids.forEach((element) => {
            let allIncludedVerticals = this.vertical_targeting.find(
              (market) => market.code === element
            );
            this.prefill_included.push(allIncludedVerticals);
          });
          this.included_vertical_ids = this.prefill_included;
        }

        if (this.excluded_vertical_ids) {
          this.excluded_vertical_ids.forEach((element) => {
            let allExcludedVerticals = this.vertical_targeting.find(
              (market) => market.code === element
            );
            this.prefill_excluded.push(allExcludedVerticals);
          });
          this.excluded_vertical_ids = this.prefill_excluded;
        }
      }
    },
  },
};
</script>
