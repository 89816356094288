<template>
  <div>
    <h6 class="mb-2">Platforms</h6>
    <v-checkbox v-model="selected_platform" dense value="PERSONAL_COMPUTER">
      <template v-slot:label>
        <span class="text-caption">Personal computer</span>
      </template>
    </v-checkbox>
    <v-checkbox v-model="selected_platform" dense value="PHONE">
      <template v-slot:label>
        <span class="text-caption">Mobile</span>
      </template>
    </v-checkbox>
    <v-checkbox v-model="selected_platform" dense value="TABLET">
      <template v-slot:label>
        <span class="text-caption">Tablet</span>
      </template>
    </v-checkbox>
    <v-checkbox v-model="selected_platform" dense value="CONNECTED_TV">
      <template v-slot:label>
        <span class="text-caption">Connected TV</span>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  data: () => ({
    selected_platform: null,
    included_platforms: [],
  }),
  props: {
    single_pretarget: {
      type: Object,
    },
  },
  watch: {
    selected_platform() {
      if (
        this.$route.name == "edit-pretarget" &&
        this.selected_platform.length
      ) {
        this.$emit("setIncludedPlatforms", this.selected_platform);
      }
      if (typeof this.selected_platform == "string") {
        this.included_platforms.push(this.selected_platform);
        this.$emit("setIncludedPlatforms", this.included_platforms);
      }
    },
    single_pretarget() {
      if (this.single_pretarget.includedPlatforms) {
        this.selected_platform = this.single_pretarget.includedPlatforms
          ? this.single_pretarget.includedPlatforms
          : null;
      }
    },
  },
};
</script>
