<template>
  <div>
    <h5 class="mb-2">App Targeting<span class="error--text">*</span></h5>
    <v-row>
      <v-col cols="12">
        <v-radio-group
          class="mb-10 required"
          v-model="targeting_mode"
          :rules="[(v) => !!v || 'Targeting mode is required!']"
        >
          <v-radio dense value="TARGETING_MODE_UNSPECIFIED" class="mb-2">
            <template v-slot:label>
              <span class="text-caption"
                >Undefined</span
              >
            </template>
          </v-radio>
          <v-radio dense value="INCLUSIVE" class="mb-2">
            <template v-slot:label>
              <span class="text-caption"
                >Include</span
              >
            </template>
          </v-radio>
          <v-radio dense value="EXCLUSIVE">
            <template v-slot:label>
              <span class="text-caption"
                >Exclude</span
              >
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>

      <v-col class="mt-5" cols="12">
        <v-combobox
          v-if="targeting_mode"
          v-model="targeted_app"
          required
          :items="available_apps"
          label="App to Target"
          outlined
          item-text="entityName"
          item-value="entityId"
          rounded
          multiple
          dense
          height="50px"
          color="#3371E2"
        >
        </v-combobox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    available_apps: [],
    apps: [],
    targeted_app: null,
    targeting_mode: null,
    prefill_included: [],
  }),
  watch: {
  },
};
</script>
