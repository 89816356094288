<template>
  <div>
    <h5 >User Lists</h5>
    <v-row class="mt-1">
      <v-col cols="12">
        <h6 class="mb-2">Included</h6>
        <v-combobox
          v-model="included_userlists"
          :items="user_lists"
          label="Choose UserLists"
          outlined
          dense
          item-text="displayName"
          item-value="displayName"
          multiple
          chips
          small-chips
          deletable-chips
        ></v-combobox>
      </v-col>

      <v-col cols="12">
        <h6 class="mb-2">Excluded</h6>
        <v-combobox
          v-model="excluded_userlists"
          :items="user_lists"
          label="Choose UserLists"
          outlined
          dense
          item-text="displayName"
          item-value="displayName"
          multiple
          chips
          small-chips
          deletable-chips
        ></v-combobox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    included_userlists: null,
    excluded_userlists: null,
    included_array: [],
    excluded_array: [],
    userlists: null,
    named: null,
  }),
  props: {
    single_pretarget: {
      type: Object,
    },
    user_lists: {
      type: Array,
    },
  },
  watch: {
    included_userlists() {
      let included_lists = this.included_userlists;
      let allids = [];
      if (included_lists.length) {
        for (let i = 0, len = included_lists.length; i < len; i++) {
          allids.push(included_lists[i].name.slice(27).trim());
        }
      }
      this.$emit("setIncludedUserListsIds", allids);
    },
    excluded_userlists() {
      let excluded_lists = this.excluded_userlists;
      let allexcludedids = [];
      if (excluded_lists.length) {
        for (let i = 0, len = excluded_lists.length; i < len; i++) {
          allexcludedids.push(excluded_lists[i].name.slice(27).trim());
        }
      }
      this.named = allexcludedids;
      this.$emit("setExcludedUserListsIds", allexcludedids);
    },
    single_pretarget() {
      if (this.single_pretarget.userListTargeting && this.single_pretarget.userListTargeting.includedIds && this.single_pretarget.userListTargeting.excludedIds) {
        this.included_userlists = this.single_pretarget.userListTargeting
          .includedIds
          ? this.single_pretarget.userListTargeting.includedIds
          : null;

        this.included_userlists.forEach((element) => {
          let all = this.user_lists.find(
            (list) => list.name.includes(element) === true
          );
          this.included_array.push(all);
        });
        this.included_userlists = this.included_array;

        this.excluded_userlists = this.single_pretarget.userListTargeting
          .excludedIds
          ? this.single_pretarget.userListTargeting.excludedIds
          : null;

        this.excluded_userlists.forEach((element) => {
          let all = this.user_lists.find(
            (list) => list.name.includes(element) === true
          );
          this.excluded_array.push(all);
        });
        this.excluded_userlists = this.excluded_array;
      }
    },
  },
};
</script>
