<template>
  <div>
    <h6 class="mb-2">Targeting Modes</h6>
    <v-checkbox v-model="usertargeting_mode" dense value="REMARKETING_ADS">
      <template v-slot:label>
        <span class="text-caption">Serve remarketing ads</span>
      </template>
    </v-checkbox>
    <v-checkbox
      v-model="usertargeting_mode"
      dense
      value="INTEREST_BASED_TARGETING"
    >
      <template v-slot:label>
        <span class="text-caption">Serve ads based on user interest</span>
      </template>
    </v-checkbox>
    <v-checkbox
      v-model="usertargeting_mode"
      dense
      value="ONLY_NON_INTERSTITIAL_REQUESTS"
    >
      <template v-slot:label>
        <span class="text-caption">Undefined user targeting</span>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  data: () => ({
    usertargeting_mode: null,
  }),
  watch: {
    usertargeting_mode() {
      this.$emit("setUserTargetingMode", this.usertargeting_mode);
    },
    single_pretarget() {
      if (this.single_pretarget) {
        this.usertargeting_mode = this.single_pretarget
          .allowedUserTargetingModes
          ? this.single_pretarget.allowedUserTargetingModes
          : null;
      }
    },
  },
  props: {
    single_pretarget: {
      type: Object,
    },
  },
};
</script>
