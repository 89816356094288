<template>
  <div>
    <h6 class="mb-2">Creative Formats<span class="error--text">*</span></h6>
    <v-checkbox v-model="included_format" value="HTML" dense>
      <template v-slot:label>
        <span class="text-caption">Html and Amphtml</span>
      </template>
    </v-checkbox>
    <v-checkbox v-model="included_format" value="VAST" dense>
      <template v-slot:label>
        <span class="text-caption">Vast video/audio</span>
      </template>
    </v-checkbox>
    <v-checkbox v-model="included_format" value="NATIVE" dense>
      <template v-slot:label>
        <span class="text-caption">Native(standard and video)</span>
      </template>
    </v-checkbox>
  </div>
</template>
<script>
export default {
  data: () => ({
    included_format: null,
    included_formats: [],
  }),
  watch: {
    included_format() {
      if (this.$route.name == "create-pretarget") {
        this.included_formats.push(this.included_format);
        this.$emit("setIncludedFormats", this.included_formats);
        return;
      }
      this.$emit("setIncludedFormats", this.included_format);
    },
    single_pretarget() {
      if (this.single_pretarget) {
        this.included_format = this.single_pretarget.includedFormats
          ? this.single_pretarget.includedFormats
          : null;
      }
    },
  },
  props: {
    single_pretarget: {
      type: Object,
    },
  },
};
</script>





