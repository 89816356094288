<template>
  <div>
    <h6 class="mb-2">Targeting Interstitial Inventory</h6>
    
    <v-checkbox
      v-model="interstitial_type"
      value="INTERSTITIAL_TARGETING_UNSPECIFIED"
      dense
    >
      <template v-slot:label>
        <span class="text-caption">Unspecified</span>
      </template>
    </v-checkbox>
    <v-checkbox
      v-model="interstitial_type"
      dense
      value="ONLY_INTERSTITIAL_REQUESTS"
    >
      <template v-slot:label>
        <span class="text-caption">Only interstitial</span>
      </template>
    </v-checkbox>
    <v-checkbox
      v-model="interstitial_type"
      dense
      value="ONLY_NON_INTERSTITIAL_REQUESTS"
    >
      <template v-slot:label>
        <span class="text-caption">Only non-interstitial</span>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  data: () => ({
    interstitial_type: null,
  }),
  watch: {
    interstitial_type() {
      this.$emit("setInstertitialTargeting", this.interstitial_type);
    },
    single_pretarget() {
      if (this.single_pretarget) {
        this.interstitial_type = this.single_pretarget.interstitialTargeting
          ? this.single_pretarget.interstitialTargeting.trim()
          : null;
      }
    },
  },
  props: {
    single_pretarget: {
      type: Object,
    },
  },
};
</script>
