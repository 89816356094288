<template>
  <div>
    <h6 class="mb-2">User Identifier</h6>
    <v-checkbox v-model="user_id" dense value="HOSTED_MATCH_DATA">
      <template v-slot:label>
        <span class="text-caption">Hosted match data</span>
      </template>
    </v-checkbox>
    <v-checkbox v-model="user_id" dense value="GOOGLE_COOKIE">
      <template v-slot:label>
        <span class="text-caption">Google user id</span>
      </template>
    </v-checkbox>
    <v-checkbox v-model="user_id" dense value="DEVICE_ID">
      <template v-slot:label>
        <span class="text-caption">Mobile device advertising id</span>
      </template></v-checkbox
    >
    <v-checkbox
      v-if="
        (user_id != 'HOSTED_MATCH_DATA' &&
          user_id != 'GOOGLE_COOKIE' &&
          user_id != 'DEVICE_ID') ||
        !user_id
      "
      v-model="user_id"
      dense
      value="USER_ID_TYPE_UNSPECIFIED"
    >
      <template v-slot:label>
        <span class="text-caption">Unspecified user identifier type</span>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  data: () => ({
    user_id: null,
    user_id_types: [],
  }),
  watch: {
    user_id() {
      if (this.$route.name == "edit-pretarget" && this.user_id.length) {
        this.$emit("setUserIdType", this.user_id);
      }
      if (typeof this.user_id == "string") {
        this.user_id_types.push(this.user_id);
        this.$emit("setUserIdType", this.user_id_types);
      }
    },
    single_pretarget() {
      if (this.single_pretarget.includedUserIdTypes) {
        this.user_id = this.single_pretarget.includedUserIdTypes
          ? this.single_pretarget.includedUserIdTypes
          : null;
      }
    },
  },
  props: {
    single_pretarget: {
      type: Object,
    },
  },
};
</script>
