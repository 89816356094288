<template>
  <v-row>
    <v-col>
      <pretarget
        :user_lists="user_lists"
        @create="addNewPretarget($event)"
        @update="updatePretarget($event)"
        :single_pretarget="single_pretarget"
        :content_labels="content_labels"
        :mobile_os="mobile_os"
        :vertical_targeting="vertical_targeting"
        :languages="languages"
        :geo_locations="geo_locations"
        :included_geo_locations_by_id="included_geo_locations_by_id"
        :excluded_geo_locations_by_id="excluded_geo_locations_by_id"
        @getGeos="geoTargeting($event)"
        @getExcludedGeos="getExcludedGeos($event)"
        @getIncludedGeosNames="getPrefillNames($event)"
        @getExcludedGeosNames="getExcludedNames($event)"
        @setTargetedSites="addtargetedSites($event)"
        @setRemoveTargetedSites="removetargetedSites($event)"
        @setAddTargetedApps="addtargetedApps($event)"
        @setRemoveTargetedApps="removetargetedApps($event)"
        @setAddTargetedPublishers="addtargetedPublishers($event)"
        @setRemoveTargetedPublishers="removetargetedPublishers($event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import Pretarget from "../../../components/Programmatic/Pretarget.vue";

export default {
  data: () => ({
    app_targeting_values: null,
    app_targeting_mode: null,
    // publisher_targeting_values: null,
    // publisher_targeting_mode: null,
    web_targeting_mode: null,
    web_targeting_values: null,
    req_params: { name: null },
    geo_object: { id: [] },
  }),

  components: {
    Pretarget,
  },

  computed: {
    user_lists() {
      return this.$store.state.dsp.user_lists;
    },
    single_pretarget() {
      return this.$store.state.dsp.single_pretarget;
    },
    content_labels() {
      return this.$store.state.dsp.content_labels;
    },
    mobile_os() {
      return this.$store.state.dsp.mobile_os;
    },
    vertical_targeting() {
      return this.$store.state.dsp.vertical_targeting;
    },
    languages() {
      return this.$store.state.dsp.languages;
    },
    geo_locations() {
      return this.$store.state.dsp.geo_locations;
    },
    included_geo_locations_by_id() {
      return this.$store.state.dsp.included_geo_locations_by_id.flat();
    },
    excluded_geo_locations_by_id() {
      return this.$store.state.dsp.excluded_geo_locations_by_id.flat();
    },
  },

  async mounted() {
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/getGoogleUserLists");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }

    if (
      this.$route.name == "edit-pretarget" ||
      this.$route.name == "edit-inventory"
    ) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "dsp/getSinglePretarget",
          this.$route.params.id
        );
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    }

    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/contentCategoryLabels");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }

    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/mobileOperatingSystemIds");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }

    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/getAllLanguages");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }

    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/getVerticalTargeting");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }
  },
  methods: {
    async geoTargeting(location) {
      this.req_params.name = location;
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getGeoLocations", this.req_params);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async getExcludedGeos(location) {
      this.req_params.name = location;
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getGeoLocations", this.req_params);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async getPrefillNames(location) {
      this.geo_object.id = location;
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/includedLocationbyId", this.geo_object);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async getExcludedNames(location) {
      this.geo_object.id = location;
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/excludedLocationbyId", this.geo_object);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async addtargetedApps(data) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/newTargetedApps",data);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Apps added successfully!",
          group: "success",
        });
        this.$router.push({ name: "pretarget", params: this.$route.params.id });
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async removetargetedApps(data) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/removeTargetedApps",data);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Apps removed successfully!",
          group: "success",
        });
        this.$router.push({ name: "pretarget", params: this.$route.params.id });
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async addtargetedPublishers(data) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/addTargetedPublishers",data);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Publisher added successfully!",
          group: "success",
        });
        this.$router.push({ name: "pretarget", params: this.$route.params.id });
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async removetargetedPublishers(data) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "dsp/removeTargetedPublishers",
          data
        );
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Publisher removed successfully!",
          group: "success",
        });
        this.$router.push({ name: "pretarget", params: this.$route.params.id });
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async addtargetedSites(data) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/addTargetedSites", data);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Sites added successfully!",
          group: "success",
        });
        this.$router.push({ name: "pretarget", params: this.$route.params.id });
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    
    async removetargetedSites(data) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/removeTargetedSites", data);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Sites removed successfully!",
          group: "success",
        });
        this.$router.push({ name: "pretarget", params: this.$route.params.id });
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async addNewPretarget(body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/createPretarget", body);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Pretarget Created successfully!",
          group: "success",
        });
        this.$router.push({ name: "programmatic-targeting" });
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async updatePretarget(body) {
      let combined_data = {
        pretarget_id: this.$route.params.id,
        body: body,
      };
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/updatePretarget", combined_data);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Updated successfully!",
          group: "success",
        });
        this.$router.push({ name: "pretarget", params: this.$route.params.id });
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
};
</script>

<style></style>
