<template>
  <div>
    <h6 class="mb-2">Environments</h6>
    <v-checkbox v-model="included_environment" dense value="APP">
      <template v-slot:label>
        <span class="text-caption">App</span>
      </template></v-checkbox
    >
    <v-checkbox v-model="included_environment" dense value="WEB">
      <template v-slot:label>
        <span class="text-caption">Web</span>
      </template></v-checkbox
    >
  </div>
</template>

<script>
export default {
  data: () => ({
    included_environment: null,
    environment_values: [],
  }),
  watch: {
    included_environment() {
      this.environment_values.push(this.included_environment);
      if (this.environment_values.length === 2) {
        this.$emit("setEnvironmentValues", this.environment_values);
      }
    },
    single_pretarget() {
      if (this.single_pretarget) {
        this.included_environment = this.single_pretarget.includedEnvironments
          ? this.single_pretarget.includedEnvironments
          : null;
      }
    },
  },
  props: {
    single_pretarget: {
      type: Object,
    },
  },
};
</script>
