var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",staticClass:"campaign-form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(this.$route.name === 'edit-inventory')?_c('div',[_c('v-breadcrumbs',{attrs:{"items":_vm.items}}),_c('v-divider')],1):_vm._e(),_c('v-row',{staticClass:"mt-2"},[(
        this.$route.name === 'create-pretarget' ||
        this.$route.name === 'edit-pretarget'
      )?_c('v-col',{attrs:{"cols":"6"}},[_c('general-card',{staticClass:"mb-3 pa-3"},[(
            this.$route.name === 'create-pretarget' ||
            this.$route.name === 'edit-pretarget'
          )?_c('div',{staticClass:"pa-5"},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Pretarget Name"),_c('span',{staticClass:"error--text"},[_vm._v("*")])]),_c('v-text-field',{staticClass:"required mb-5",attrs:{"required":"","single-line":"","label":"Pretarget name","outlined":"","rounded":"","dense":"","height":"30px","color":"#3371E2","rules":[(v) => !!v || 'Pretarget name is required!']},model:{value:(_vm.displayName),callback:function ($$v) {_vm.displayName=$$v},expression:"displayName"}}),_c('h6',{staticClass:"mt-3 mb-2"},[_vm._v("Maximum QPS")]),_c('v-text-field',{attrs:{"type":"number","required":"","single-line":"","label":"For example:300 bid requests/second","outlined":"","rounded":"","dense":"","min":"0","height":"50px","color":"#3371E2"},model:{value:(_vm.maximum_qps),callback:function ($$v) {_vm.maximum_qps=$$v},expression:"maximum_qps"}},[_vm._v("No Negative Values")]),_c('h6',{staticClass:"mt-3 mb-2"},[_vm._v("Minimum viewability")]),_c('v-text-field',{attrs:{"type":"number","required":"","single-line":"","label":"","outlined":"","rounded":"","dense":"","min":"0","max":"10","height":"50px","color":"#3371E2"},model:{value:(_vm.viewability),callback:function ($$v) {_vm.viewability=$$v},expression:"viewability"}},[_vm._v("No Negative Values")])],1):_vm._e()]),_c('general-card',{staticClass:"mb-3 pa-3"},[(
            this.$route.name === 'create-pretarget' ||
            this.$route.name === 'edit-pretarget'
          )?_c('div',{staticClass:"pa-5"},[_c('geo-targeting',{attrs:{"single_pretarget":_vm.single_pretarget,"geo_locations":_vm.geo_locations,"included_geo_locations_by_id":_vm.included_geo_locations_by_id,"excluded_geo_locations_by_id":_vm.excluded_geo_locations_by_id},on:{"setIncludedGeosIds":function($event){return _vm.getIncludedGeoIds($event)},"setSelectedIncludedGeos":function($event){return _vm.setSelectedIncludedGeoids($event)},"setExcludedGeosIds":function($event){return _vm.getExcludedGeoIds($event)},"setSelectedExcludedGeos":function($event){return _vm.getSelectedGeoIds($event)},"getIncludedGeosNames":function($event){return _vm.getIncludedLocationName($event)},"getExcludedGeosNames":function($event){return _vm.getExcludedLocationsName($event)}}}),_c('targeted-user-lists',{staticClass:"mt-5",attrs:{"user_lists":_vm.user_lists,"single_pretarget":_vm.single_pretarget},on:{"setIncludedUserListsIds":function($event){return _vm.getIncludedUserLists($event)},"setExcludedUserListsIds":function($event){return _vm.getExcluedUserListsIds($event)}}}),_c('vertical-targeting',{staticClass:"mt-5",attrs:{"single_pretarget":_vm.single_pretarget,"vertical_targeting":_vm.vertical_targeting},on:{"setIncludedVerticals":function($event){return _vm.getIncludedVerticals($event)},"setExcludedVerticals":function($event){return _vm.getExcludedVerticals($event)}}})],1):_vm._e()]),_c('general-card',{staticClass:"mb-3 pa-3"},[(
            this.$route.name === 'create-pretarget' ||
            this.$route.name === 'edit-pretarget'
          )?_c('div',{staticClass:"pa-5"},[_c('creative-formats',{attrs:{"single_pretarget":_vm.single_pretarget},on:{"setIncludedFormats":function($event){return _vm.getCreativeFormats($event)}}}),_c('included-creative-dimensions',{staticClass:"mt-5",attrs:{"single_pretarget":_vm.single_pretarget},on:{"setCreativeDimensions":function($event){return _vm.getCreativeDimensions($event)}}}),_c('included-platforms',{staticClass:"mt-5",attrs:{"single_pretarget":_vm.single_pretarget},on:{"setIncludedPlatforms":function($event){return _vm.getIncludedPlatforms($event)}}}),_c('targeting-mode',{attrs:{"single_pretarget":_vm.single_pretarget},on:{"setUserTargetingMode":function($event){return _vm.getUserTargetingMode($event)}}}),_c('instertitial-targeting',{staticClass:"mt-5",attrs:{"single_pretarget":_vm.single_pretarget},on:{"setInstertitialTargeting":function($event){return _vm.getInstertitialType($event)}}})],1):_vm._e()])],1):_vm._e(),_c('v-col',[_c('general-card',{staticClass:"mb-3 pa-3"},[(
            this.$route.name === 'create-pretarget' ||
            this.$route.name === 'edit-pretarget'
          )?_c('div',{staticClass:"pa-5"},[_c('languages',{attrs:{"single_pretarget":_vm.single_pretarget,"languages":_vm.languages},on:{"setIncludedLanguages":function($event){return _vm.getIncludedLanguages($event)}}}),_c('mobile-os',{staticClass:"mt-5",attrs:{"single_pretarget":_vm.single_pretarget,"mobile_os":_vm.mobile_os},on:{"setIncludedMobileOperatingSystemIds":function($event){return _vm.getMobileOS($event)}}}),_c('included-environments',{staticClass:"mt-5",attrs:{"single_pretarget":_vm.single_pretarget},on:{"setEnvironmentTargetingMode":function($event){return _vm.getEnvironmentTargetingMode($event)},"setEnvironmentValues":function($event){return _vm.getEnvironmentValues($event)}}}),_c('excluded-content-labels',{staticClass:"mt-5",attrs:{"single_pretarget":_vm.single_pretarget,"content_labels":_vm.content_labels},on:{"setExcludedContentLabelIds":function($event){return _vm.getExcludedContent($event)}}}),_c('user-id-types',{staticClass:"mt-5",attrs:{"single_pretarget":_vm.single_pretarget},on:{"setUserIdType":function($event){return _vm.getUserIdTypes($event)}}})],1):_vm._e()]),_c('general-card',[(
            this.$route.name === 'create-pretarget' ||
            this.$route.name === 'edit-inventory'
          )?_c('div',{staticClass:"pa-5 mt-3"},[_c('web-targeting',{attrs:{"single_pretarget":_vm.single_pretarget},on:{"setTargetedSites":function($event){return _vm.getTargetedSites($event)},"setWebTargetingMode":function($event){return _vm.getSiteTargetingMode($event)}}}),(_vm.web_targeting_values.length)?_c('v-btn',{staticClass:"mr-3 mt-5",attrs:{"small":"","color":"success"},on:{"click":_vm.addtargetedSites}},[_vm._v("Add Targeted Sites")]):_vm._e(),(
              this.$route.name === 'edit-inventory' &&
              _vm.single_pretarget.webTargeting
            )?_c('v-btn',{staticClass:"mt-5",attrs:{"small":"","color":"error"},on:{"click":_vm.removetargetedSites}},[_vm._v("Remove Targeted Sites")]):_vm._e(),_c('mobile-app-targeting',{staticClass:"mt-5",attrs:{"single_pretarget":_vm.single_pretarget},on:{"setTargetedApps":function($event){return _vm.getTargetedApps($event)},"setAppTargetingMode":function($event){return _vm.getAppTargetingMode($event)}}}),(_vm.app_targeting_values.length)?_c('v-btn',{staticClass:"mr-3 mt-5",attrs:{"small":"","color":"success"},on:{"click":_vm.addtargetedApps}},[_vm._v("Add Targeted Apps")]):_vm._e(),(
              this.$route.name === 'edit-inventory' &&
              _vm.single_pretarget.appTargeting
            )?_c('v-btn',{staticClass:"mt-5",attrs:{"small":"","color":"error"},on:{"click":_vm.removetargetedApps}},[_vm._v("Remove Targeted Apps")]):_vm._e(),_c('publisher-targeting',{staticClass:"mt-5",attrs:{"single_pretarget":_vm.single_pretarget},on:{"setTargetedPublishers":function($event){return _vm.getPublishers($event)},"setPublisherTargetingMode":function($event){return _vm.getPublisherTargetingMode($event)}}}),(_vm.publisher_targeting_values.length)?_c('v-btn',{staticClass:"mr-3 mt-5",attrs:{"small":"","color":"success"},on:{"click":_vm.addtargetedPublishers}},[_vm._v("Add Targeted Publishers")]):_vm._e(),(
              this.$route.name === 'edit-inventory' &&
              _vm.single_pretarget.publisherTargeting
            )?_c('v-btn',{staticClass:"mt-5",attrs:{"small":"","color":"error"},on:{"click":_vm.removetargetedPublishers}},[_vm._v("Remove Targeted Publishers")]):_vm._e()],1):_vm._e(),_c('v-col',{staticClass:"d-flex justify-end"},[(this.$route.name === 'create-pretarget')?_c('v-btn',{staticClass:"custom-btn my-5",attrs:{"small":"","depressed":"","outlined":"","color":"#8cc73e"},on:{"click":function($event){$event.stopPropagation();return _vm.create.apply(null, arguments)}}},[_vm._v(" Create Google Target ")]):_vm._e(),(this.$route.name === 'edit-pretarget')?_c('v-btn',{staticClass:"custom-btn my-5",attrs:{"small":"","depressed":"","outlined":"","color":"#8cc73e"},on:{"click":function($event){$event.stopPropagation();return _vm.update.apply(null, arguments)}}},[_vm._v(" Update Google Target ")]):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }