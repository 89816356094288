<template>
  <div>
    <h6 class="mb-2">Excluded Content Labels</h6>
    <v-combobox
      v-model="excluded_label_ids"
      :items="content_labels"
      label="Choose Excluded Content"
      outlined
      dense
      item-text="label"
      item-value="label"
      multiple
    ></v-combobox>
  </div>
</template>

<script>
export default {
  data: () => ({
    excluded_label_ids: null,
    labels_array: [],
  }),
  props: {
    single_pretarget: {
      type: Object,
    },
    content_labels: {
      type: Array,
    },
  },
  watch: {
    excluded_label_ids() {
      let labels = this.excluded_label_ids;
      let alllabelids = [];
      if (labels.length) {
        for (let i = 0, len = labels.length; i < len; i++) {
          alllabelids.push(labels[i].code);
        }
      }
      this.$emit("setExcludedContentLabelIds", alllabelids);
    },
    single_pretarget() {
      if (this.single_pretarget) {
        this.excluded_label_ids = this.single_pretarget.excludedContentLabelIds
          ? this.single_pretarget.excludedContentLabelIds
          : null;
      }
    },
    content_labels() {
      if (
        this.content_labels.length &&
        this.excluded_label_ids &&
        this.excluded_label_ids.length
      ) {
        this.excluded_label_ids.forEach((element) => {
          let all = this.content_labels.find((label) => label.code === element);
          this.labels_array.push(all);
        });
        this.excluded_label_ids = this.labels_array;
      }
    },
  },
};
</script>
