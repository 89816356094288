<template>
  <div>
    <h5 class="mb-2">Publisher Targeting<span class="error--text">*</span></h5>
    <v-row>
      <v-col cols="12">
        <v-radio-group
          class="mb-10 required"
          v-model="targeting_mode"
          :rules="[(v) => !!v || 'Targeting mode is required!']"
        >
          <v-radio dense value="TARGETING_MODE_UNSPECIFIED" class="mb-2">
            <template v-slot:label>
              <span class="text-caption"
                >Undefined</span
              >
            </template>
          </v-radio>
          <v-radio dense value="INCLUSIVE" class="mb-2">
            <template v-slot:label>
              <span class="text-caption"
                >Inventory must match an item in this list to be targeted</span
              >
            </template>
          </v-radio>
          <v-radio dense value="EXCLUSIVE">
            <template v-slot:label>
              <span class="text-caption"
                >Inventory must not match any item in this list to be
                targeted</span
              >
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>

      <v-col class="mt-5" cols="12">
        <v-combobox
          v-if="targeting_mode"
          v-model="targeted_publisher"
          required
          single-line
          item-text="displayName"
          item-value="profileId"
          :items="available_publishers"
          label="Publishers to Target"
          multiple
          outlined
          rounded
          dense
          height="50px"
          color="#3371E2"
        >
        </v-combobox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    publishers: [],
    targeted_publisher: null,
    targeting_mode: null,
    available_publishers: [],
    prefill_included: [],
  }),
};
</script>
