<template>
  <div>
    <h5>Locations</h5>
    <v-row class="mt-2">
      <v-col cols="12">
        <h6 class="mb-2">Included</h6>
        <div class="d-flex align-center">
          <v-text-field
            v-model="included_geoids"
            outlined
            dense
            label="Type in location"
            class="mr-1"
          ></v-text-field>
          <v-btn @click="fetch()" class="mr-4" small>Browse</v-btn>
          <v-combobox
            v-model="selected_included"
            label="Choose location"
            :items="geo_locations"
            outlined
            dense
            item-text="location_name"
            item-value="geo_id"
            :disabled="!geo_locations.length"
            multiple
            deletable-chips
          >
            <template v-slot:selection="{ item, index }">
              <v-chip small v-if="index < 1">
                <span>{{ item.location_name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ selected_included.length - 1 }} others)
              </span>
            </template>
          </v-combobox>
        </div>
      </v-col>
      <v-col>
        <h6 class="mb-2">Excluded</h6>
        <div class="d-flex align-center">
          <v-text-field
            v-model="excluded_geoids"
            outlined
            dense
            label="Type in location"
            class="mr-1"
          ></v-text-field>
          <v-btn @click="fetchExcluded()" small class="mr-4">Browse</v-btn>
          <v-combobox
            v-model="selected_excluded"
            label="Choose location"
            :items="geo_locations"
            outlined
            dense
            item-text="location_name"
            item-value="geo_id"
            multiple
            :disabled="!geo_locations.length"
            chips
            small-chips
            deletable-chips
          >
            <template v-slot:selection="{ item, index }">
              <v-chip small v-if="index < 1">
                <span>{{ item.location_name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ selected_excluded.length - 1 }})
              </span>
            </template>
          </v-combobox>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    included_geoids: "",
    excluded_geoids: "",
    selected_included: null,
    selected_excluded: null,
    locations: null,
    all: null,
  }),
  props: {
    single_pretarget: {
      type: Object,
    },
    included_geo_locations_by_id: {
      type: Array,
    },
    excluded_geo_locations_by_id: {
      type: Array,
    },
    geo_locations: {
      type: Array,
    },
  },
  methods: {
    fetch() {
      let capitalized = this.included_geoids;
      this.$emit(
        "setIncludedGeosIds",
        capitalized.charAt(0).toUpperCase() + capitalized.slice(1)
      );
    },
    fetchExcluded() {
      let capitalized = this.excluded_geoids;
      this.$emit(
        "setExcludedGeosIds",
        capitalized.charAt(0).toUpperCase() + capitalized.slice(1)
      );
    },
  },
  watch: {
    included_geo_locations_by_id() {
      if (this.single_pretarget.geoTargeting.includedIds) {
        let locations = [];
        for (let i = 0; i < this.included_geoids.length; i++) {
          let match = this.included_geo_locations_by_id.find(
            (location) => location.geo_id === this.included_geoids[i]
          );
          if (match) locations.push(match);
        }
        this.selected_included = locations;
        this.included_geoids = "";
      }
    },
    excluded_geo_locations_by_id() {
      if (this.single_pretarget.geoTargeting.excludedIds) {
        let locations = [];
        for (let i = 0; i < this.excluded_geoids.length; i++) {
          let match = this.excluded_geo_locations_by_id.find(
            (location) => location.geo_id === this.excluded_geoids[i]
          );
          if (match) locations.push(match);
        }
        this.selected_excluded = locations;
        this.excluded_geoids = "";
      }
    },
    selected_included() {
      let allIds = [];
      for (let i = 0, len = this.selected_included.length; i < len; i++) {
        allIds.push(this.selected_included[i].geo_id);
      }
      this.$emit("setSelectedIncludedGeos", allIds);
    },
    selected_excluded() {
      let allIds = [];
      for (let i = 0, len = this.selected_excluded.length; i < len; i++) {
        allIds.push(this.selected_excluded[i].geo_id);
      }
      this.$emit("setSelectedExcludedGeos", allIds);
    },
    single_pretarget() {
      if (this.single_pretarget.geoTargeting.includedIds) {
        this.included_geoids = this.single_pretarget.geoTargeting.includedIds
          ? this.single_pretarget.geoTargeting.includedIds
          : null;
        this.$emit("getIncludedGeosNames", this.included_geoids);
      }
      if (this.single_pretarget.geoTargeting.excludedIds) {
        this.excluded_geoids = this.single_pretarget.geoTargeting.excludedIds
          ? this.single_pretarget.geoTargeting.excludedIds
          : null;
        this.$emit("getExcludedGeosNames", this.excluded_geoids);
      }
    },
  },
};
</script>
