<template>
  <v-form ref="form" lazy-validation class="campaign-form" v-model="valid">
    <div v-if="this.$route.name === 'edit-inventory'">
      <v-breadcrumbs :items="items"></v-breadcrumbs>
      <v-divider></v-divider>
    </div>
    <v-row class="mt-2">
      <v-col
        v-if="
          this.$route.name === 'create-pretarget' ||
          this.$route.name === 'edit-pretarget'
        "
        cols="6"
      >
        <general-card class="mb-3 pa-3">
          <div
            class="pa-5"
            v-if="
              this.$route.name === 'create-pretarget' ||
              this.$route.name === 'edit-pretarget'
            "
          >
            <h6 class="mb-2">
              Pretarget Name<span class="error--text">*</span>
            </h6>
            <v-text-field
              v-model="displayName"
              required
              single-line
              label="Pretarget name"
              outlined
              rounded
              dense
              height="30px"
              color="#3371E2"
              class="required mb-5"
              :rules="[(v) => !!v || 'Pretarget name is required!']"
            ></v-text-field>

            <h6 class="mt-3 mb-2">Maximum QPS</h6>

            <v-text-field
              type="number"
              v-model="maximum_qps"
              required
              single-line
              label="For example:300 bid requests/second"
              outlined
              rounded
              dense
              min="0"
              height="50px"
              color="#3371E2"
              >No Negative Values</v-text-field
            >

            <h6 class="mt-3 mb-2">Minimum viewability</h6>
            <v-text-field
              type="number"
              v-model="viewability"
              required
              single-line
              label=""
              outlined
              rounded
              dense
              min="0"
              max="10"
              height="50px"
              color="#3371E2"
              >No Negative Values</v-text-field
            >
          </div>
        </general-card>
        <general-card class="mb-3 pa-3">
          <div
            class="pa-5"
            v-if="
              this.$route.name === 'create-pretarget' ||
              this.$route.name === 'edit-pretarget'
            "
          >
            <geo-targeting
              @setIncludedGeosIds="getIncludedGeoIds($event)"
              @setSelectedIncludedGeos="setSelectedIncludedGeoids($event)"
              @setExcludedGeosIds="getExcludedGeoIds($event)"
              @setSelectedExcludedGeos="getSelectedGeoIds($event)"
              @getIncludedGeosNames="getIncludedLocationName($event)"
              @getExcludedGeosNames="getExcludedLocationsName($event)"
              :single_pretarget="single_pretarget"
              :geo_locations="geo_locations"
              :included_geo_locations_by_id="included_geo_locations_by_id"
              :excluded_geo_locations_by_id="excluded_geo_locations_by_id"
            />

            <targeted-user-lists
              class="mt-5"
              :user_lists="user_lists"
              @setIncludedUserListsIds="getIncludedUserLists($event)"
              @setExcludedUserListsIds="getExcluedUserListsIds($event)"
              :single_pretarget="single_pretarget"
            />

            <vertical-targeting
              class="mt-5"
              @setIncludedVerticals="getIncludedVerticals($event)"
              @setExcludedVerticals="getExcludedVerticals($event)"
              :single_pretarget="single_pretarget"
              :vertical_targeting="vertical_targeting"
            />
          </div>
        </general-card>
        <general-card class="mb-3 pa-3">
          <div
            class="pa-5"
            v-if="
              this.$route.name === 'create-pretarget' ||
              this.$route.name === 'edit-pretarget'
            "
          >
            <creative-formats
              @setIncludedFormats="getCreativeFormats($event)"
              :single_pretarget="single_pretarget"
            />

            <included-creative-dimensions
              class="mt-5"
              @setCreativeDimensions="getCreativeDimensions($event)"
              :single_pretarget="single_pretarget"
            />

            <included-platforms
              class="mt-5"
              @setIncludedPlatforms="getIncludedPlatforms($event)"
              :single_pretarget="single_pretarget"
            />

            <targeting-mode
              @setUserTargetingMode="getUserTargetingMode($event)"
              :single_pretarget="single_pretarget"
            />

            <instertitial-targeting
              class="mt-5"
              @setInstertitialTargeting="getInstertitialType($event)"
              :single_pretarget="single_pretarget"
            />
          </div>
        </general-card>
      </v-col>
      <v-col>
        <general-card class="mb-3 pa-3">
          <div
            class="pa-5"
            v-if="
              this.$route.name === 'create-pretarget' ||
              this.$route.name === 'edit-pretarget'
            "
          >
            <languages
              @setIncludedLanguages="getIncludedLanguages($event)"
              :single_pretarget="single_pretarget"
              :languages="languages"
            />
            <mobile-os
              class="mt-5"
              @setIncludedMobileOperatingSystemIds="getMobileOS($event)"
              :single_pretarget="single_pretarget"
              :mobile_os="mobile_os"
            />

            <included-environments
              class="mt-5"
              @setEnvironmentTargetingMode="getEnvironmentTargetingMode($event)"
              @setEnvironmentValues="getEnvironmentValues($event)"
              :single_pretarget="single_pretarget"
            />
            <excluded-content-labels
              class="mt-5"
              @setExcludedContentLabelIds="getExcludedContent($event)"
              :single_pretarget="single_pretarget"
              :content_labels="content_labels"
            />
            <user-id-types
              class="mt-5"
              @setUserIdType="getUserIdTypes($event)"
              :single_pretarget="single_pretarget"
            />
          </div>
        </general-card>
        <general-card>
          <div
            class="pa-5 mt-3"
            v-if="
              this.$route.name === 'create-pretarget' ||
              this.$route.name === 'edit-inventory'
            "
          >
            <web-targeting
              @setTargetedSites="getTargetedSites($event)"
              @setWebTargetingMode="getSiteTargetingMode($event)"
              :single_pretarget="single_pretarget"
            />
            <v-btn
              v-if="web_targeting_values.length"
              @click="addtargetedSites"
              small
              class="mr-3 mt-5"
              color="success"
              >Add Targeted Sites</v-btn
            >
            <v-btn
              v-if="
                this.$route.name === 'edit-inventory' &&
                single_pretarget.webTargeting
              "
              @click="removetargetedSites"
              small
              class="mt-5"
              color="error"
              >Remove Targeted Sites</v-btn
            >

            <mobile-app-targeting
              class="mt-5"
              @setTargetedApps="getTargetedApps($event)"
              @setAppTargetingMode="getAppTargetingMode($event)"
              :single_pretarget="single_pretarget"
            />
            <v-btn
              v-if="app_targeting_values.length"
              @click="addtargetedApps"
              small
              class="mr-3 mt-5"
              color="success"
              >Add Targeted Apps</v-btn
            >
            <v-btn
              v-if="
                this.$route.name === 'edit-inventory' &&
                single_pretarget.appTargeting
              "
              @click="removetargetedApps"
              small
              color="error"
              class="mt-5"
              >Remove Targeted Apps</v-btn
            >

            <publisher-targeting
              class="mt-5"
              @setTargetedPublishers="getPublishers($event)"
              @setPublisherTargetingMode="getPublisherTargetingMode($event)"
              :single_pretarget="single_pretarget"
            />
            <v-btn
              v-if="publisher_targeting_values.length"
              @click="addtargetedPublishers"
              small
              class="mr-3 mt-5"
              color="success"
              >Add Targeted Publishers</v-btn
            >
            <v-btn
              v-if="
                this.$route.name === 'edit-inventory' &&
                single_pretarget.publisherTargeting
              "
              @click="removetargetedPublishers"
              small
              class="mt-5"
              color="error"
              >Remove Targeted Publishers</v-btn
            >
          </div>

          <v-col class="d-flex justify-end">
            <v-btn
              v-if="this.$route.name === 'create-pretarget'"
              @click.stop="create"
              small
              depressed
              outlined
              color="#8cc73e"
              class="custom-btn my-5"
            >
              Create Google Target
            </v-btn>
            <v-btn
              v-if="this.$route.name === 'edit-pretarget'"
              @click.stop="update"
              small
              depressed
              outlined
              color="#8cc73e"
              class="custom-btn my-5"
            >
              Update Google Target
            </v-btn>
          </v-col>
        </general-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import CreativeFormats from "../Programmatic/CreativeFormats.vue";
import GeoTargeting from "../../components/Programmatic/GeoTargeting.vue";
import TargetedUserLists from "../Programmatic/TargetedUserLists.vue";
import InstertitialTargeting from "../../components/Programmatic/InterstitialTargeting.vue";
import TargetingMode from "../../components/Programmatic/TargetingMode.vue";
import ExcludedContentLabels from "../../components/Programmatic/ExcludedContentLabels.vue";
import UserIdTypes from "../../components/Programmatic/UserIdTypes.vue";
import Languages from "../../components/Programmatic/Languages.vue";
import MobileOs from "../../components/Programmatic/MobileOS.vue";
import VerticalTargeting from "../../components/Programmatic/VerticalTargeting.vue";
import IncludedPlatforms from "../../components/Programmatic/IncludedPlatforms.vue";
import IncludedCreativeDimensions from "../../components/Programmatic/IncludedCreativeDimensions.vue";
import IncludedEnvironments from "../../components/Programmatic/IncludedEnvironments.vue";
import WebTargeting from "../../components/Programmatic/WebTargeting.vue";
import PublisherTargeting from "../../components/Programmatic/PublisherTargeting.vue";
import MobileAppTargeting from "../../components/Programmatic/MobileAppTargeting.vue";
import GeneralCard from "../Layouts/Cards/GeneralCard.vue";

export default {
  data: () => ({
    valid: true,
    displayName: null,
    includedFormats: null,
    included_geos: null,
    excluded_geos: null,
    included_userlists: null,
    excluded_userlists: null,
    instertitial_targeting: null,
    user_targeting_mode: null,
    excluded_content_labels: null,
    user_id_types: null,
    included_langauges: null,
    included_mobile_operating_systems: null,
    included_verticalids: null,
    excluded_verticalids: null,
    included_platforms: null,
    included_creative_dimensions: null,
    environment_values: null,
    environment_targeting_mode: null,
    web_targeting_mode: null,
    web_targeting_values: [],
    publisher_targeting_mode: null,
    publisher_targeting_values: [],
    app_targeting_mode: null,
    app_targeting_values: [],
    maximum_qps: 50,
    viewability: 5,
    appIds: null,
    targetingMode: null,
    locations_included: null,
    selected_excluded_locations: null,
    items: [
      {
        text: "Pretargets",
        disabled: false,
        href: "#/tradedesk/targeting",
      },
      {
        text: "Single Pretarget",
        disabled: true,
        href: "#",
      },
    ],
  }),
  props: {
    isValid: {
      type: Boolean,
    },
    user_lists: {
      type: Array,
    },
    single_pretarget: {
      type: Object,
    },
    content_labels: {
      type: Array,
    },
    mobile_os: {
      type: Array,
    },
    vertical_targeting: {
      type: Array,
    },
    languages: {
      type: Array,
    },
    geo_locations_by_id: {
      type: Array,
    },
    included_geo_locations_by_id: {
      type: Array,
    },
    excluded_geo_locations_by_id: {
      type: Array,
    },
    geo_locations: {
      type: Array,
    },
  },
  components: {
    CreativeFormats,
    GeneralCard,
    GeoTargeting,
    TargetedUserLists,
    InstertitialTargeting,
    TargetingMode,
    UserIdTypes,
    ExcludedContentLabels,
    Languages,
    MobileOs,
    VerticalTargeting,
    IncludedPlatforms,
    IncludedCreativeDimensions,
    IncludedEnvironments,
    WebTargeting,
    PublisherTargeting,
    MobileAppTargeting,
  },
  methods: {
    capitalizeFirstLetter(string) {
      const capitalized = string.charAt(0).toUpperCase() + string.slice(1);
      return capitalized;
    },
    breakArray(array) {
      if (array.length) {
        for (var i = 0; i < array.length; i++) {
          return array[i].trim();
        }
      }
    },
    getCreativeFormats(creative_formats) {
      this.includedFormats = creative_formats;
    },
    getIncludedUserLists(included) {
      this.included_userlists = included;
    },
    getExcluedUserListsIds(excluded) {
      this.excluded_userlists = excluded;
    },
    getIncludedGeoIds(includedgeos) {
      this.$emit("getGeos", includedgeos);
    },
    setSelectedIncludedGeoids(selectedgeos) {
      this.locations_included = selectedgeos;
    },
    getSelectedGeoIds(selected_excluded) {
      this.selected_excluded_locations = selected_excluded;
    },
    getExcludedGeoIds(excludedgeos) {
      this.$emit("getExcludedGeos", excludedgeos);
    },
    getIncludedLocationName(prefill_name) {
      this.$emit("getIncludedGeosNames", prefill_name);
    },
    getExcludedLocationsName(prefill_excluded_name) {
      this.$emit("getExcludedGeosNames", prefill_excluded_name);
    },
    getInstertitialType(instertitial_type) {
      this.instertitial_targeting = instertitial_type;
    },
    getUserTargetingMode(targeting_mode) {
      this.user_targeting_mode = targeting_mode;
    },
    getExcludedContent(excluded_content) {
      this.excluded_content_labels = excluded_content;
    },
    getUserIdTypes(user_id_type) {
      this.user_id_types = user_id_type;
    },
    getIncludedLanguages(languages) {
      this.included_langauges = languages;
    },
    getMobileOS(mobileOSs) {
      this.included_mobile_operating_systems = mobileOSs;
    },
    getIncludedVerticals(includedverticals) {
      this.included_verticalids = includedverticals;
    },
    getExcludedVerticals(excludedverticals) {
      this.excluded_verticalids = excludedverticals;
    },
    getIncludedPlatforms(includedplatforms) {
      this.included_platforms = includedplatforms;
    },
    getCreativeDimensions(creative_dimensions) {
      this.included_creative_dimensions = creative_dimensions;
    },
    getEnvironmentValues(values) {
      this.environment_values = values;
    },
    getTargetedSites(targeted_sites) {
      this.web_targeting_values = targeted_sites;
    },
    getSiteTargetingMode(site_targeting_mode) {
      this.web_targeting_mode = site_targeting_mode;
    },
    getPublishers(publishers) {
      this.publisher_targeting_values = publishers;
    },
    getPublisherTargetingMode(mode) {
      this.publisher_targeting_mode = mode;
    },
    getTargetedApps(apps) {
      this.app_targeting_values = apps;
    },
    getAppTargetingMode(apps_mode) {
      this.app_targeting_mode = apps_mode;
    },

    addtargetedSites() {
      let combined_data = {
        pretarget_id: this.$route.params.id,
        body: {
          sites: this.web_targeting_values,
          targetingMode: this.web_targeting_mode,
        },
      };
      this.$emit("setTargetedSites", combined_data);
    },

    removetargetedSites() {
      let combined_data = {
        pretarget_id: this.$route.params.id,
        body: {
          sites: this.web_targeting_values,
          targetingMode: this.web_targeting_mode,
        },
      };
      this.$emit("setRemoveTargetedSites", combined_data);
    },

    addtargetedApps() {
      let combined_data = {
        pretarget_id: this.$route.params.id,
        body: {
          appIds: this.app_targeting_values,
          targetingMode: this.app_targeting_mode,
        },
      };
      this.$emit("setAddTargetedApps", combined_data);
    },

    removetargetedApps() {
      let combined_data = {
        pretarget_id: this.$route.params.id,
        body: {
          appIds: this.app_targeting_values,
          targetingMode: this.app_targeting_mode,
        },
      };
      this.$emit("setRemoveTargetedApps", combined_data);
    },

    addtargetedPublishers() {
      let combined_data = {
        pretarget_id: this.$route.params.id,
        body: {
          publisherIds: this.publisher_targeting_values,
          targetingMode: this.publisher_targeting_mode,
        },
      };
      this.$emit("setAddTargetedPublishers", combined_data);
    },

    removetargetedPublishers() {
      let combined_data = {
        pretarget_id: this.$route.params.id,
        body: {
          publisherIds: this.publisher_targeting_values,
          targetingMode: this.publisher_targeting_mode,
        },
      };
      this.$emit("setRemoveTargetedPublishers", combined_data);
    },

    async create() {
      await this.$refs.form.validate();
      if (!this.valid) return;

      const body = {
        displayName: this.displayName,
        maximum_qps: this.maximum_qps,
        minimum_viewability_decile: this.viewability,
        interstitial_targeting: this.instertitial_targeting,
        included_creative_dimensions: this.included_creative_dimensions,
        included_formats: this.includedFormats,
        allowed_user_targeting_modes: this.user_targeting_mode,
        excluded_content_label_ids: this.excluded_content_labels,
        included_user_id_types: this.user_id_types,
        included_languages: this.included_langauges,
        included_mobile_os_ids: this.included_mobile_operating_systems,
        vertical_targeting: [
          {
            included_vertical_ids: this.included_verticalids,
            excluded_vertical_ids: this.excluded_verticalids,
          },
        ],
        included_platforms: this.included_platforms,
        included_environments: this.environment_values,
        geo_targeting: [
          {
            excluded_geo_ids: this.locations_included,
            included_geo_ids: this.selected_excluded_locations,
          },
        ],
        web_targeting: [
          {
            web_targeting_mode: this.web_targeting_mode,
            web_targeting_urls: this.web_targeting_values,
          },
        ],
        publisher_targeting: [
          {
            publisher_targeting_mode: "EXCLUSIVE",
            publisher_ids: ["1234"],
          },
        ],
        mobile_app_targeting: [
          {
            mobile_app_targeting_mode: this.app_targeting_mode,
            mobile_app_targeting_app_ids: this.app_targeting_values,
          },
        ],
        mobile_app_category_targeting: [
          {
            included_mobile_app_targeting_category_ids: [],
            excluded_mobile_app_targeting_category_ids: [],
          },
        ],
        user_list_targeting: [
          {
            included_user_list_ids: this.included_userlists,
            excluded_user_list_ids: this.excluded_userlists,
          },
        ],
      };
      // this.$emit("create", body);
      let sanitized_data = await this.sanitizedData(body);
      this.$emit("create", sanitized_data);
    },

    async sanitizedData(data) {
      //Remove null or undefined entries
      !data.maximum_qps ? delete data.maximum_qps : null;
      !data.minimum_viewability_decile
        ? delete data.minimum_viewability_decile
        : null;
      if (this.included_creative_dimensions == null) {
        data.included_creative_dimensions = [
          { height: this.height, width: this.width },
        ];
      }
      return data;
    },

    async update() {
      const body = {
        displayName: this.displayName,
        maximum_qps: this.maximum_qps ? Number(this.maximum_qps.trim()): 50,
        minimum_viewability_decile: this.viewability ? Number(this.viewability.trim()) : 5,
        interstitial_targeting: this.instertitial_targeting,
        included_creative_dimensions: this.included_creative_dimensions,
        included_formats: this.includedFormats,
        allowed_user_targeting_modes: this.user_targeting_mode,
        excluded_content_label_ids: this.excluded_content_labels,
        included_user_id_types: this.user_id_types,
        included_languages: this.included_langauges,
        included_mobile_os_ids: this.included_mobile_operating_systems,
        vertical_targeting: {
          includedIds: this.included_verticalids,
          excludedIds: this.excluded_verticalids,
        },
        included_platforms: this.included_platforms,
        included_environments: this.environment_values,
        geo_targeting: {
          includedIds: this.locations_included,
          excludedIds: this.selected_excluded_locations,
        },
        web_targeting: {
          targetingMode: this.web_targeting_mode,
          values: this.web_targeting_values,
        },
        publisher_targeting: {
          targetingMode: this.publisher_targeting_mode,
          values: this.publisher_targeting_values,
        },
        mobile_app_targeting: {
          targetingMode: this.app_targeting_mode,
          values: this.app_targeting_values,
        },
        user_list_targeting: {
          includedIds: this.included_userlists,
          excludedIds: this.excluded_userlists,
        },
      };
      let sanitized_data = await this.sanitizedData(body);
      this.$emit("update", sanitized_data);
    },
  },

  watch: {
    single_pretarget() {
      if (this.single_pretarget) {
        this.displayName = this.single_pretarget.displayName
          ? this.single_pretarget.displayName
          : null;
        this.viewability = this.single_pretarget.minimumViewabilityDecile
          ? this.single_pretarget.minimumViewabilityDecile.trim()
          : null;
        this.maximum_qps = this.single_pretarget.maximumQps
          ? this.single_pretarget.maximumQps.trim()
          : null;
      }
    },
  },
};
</script>

<style></style>
