<template>
<div>
</div>
</template>

<script>
export default {
  data: () => ({
    sites: [],
    available_sites: [],
    targeted_site: null,
    targeting_mode: null,
    prefill_included: [],
  }),
  props: {
    single_pretarget: {
      type: Object,
    },
  },
};
</script>
